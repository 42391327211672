export const groupActionTypes = {
    SET_INITIAL_GROUP_DATA: "SET_INITIAL_GROUP_DATA",
    SET_TAX_ID: "SET_TAX_ID",
    SET_LEGAL_ENTITY_NAME: "SET_LEGAL_ENTITY_NAME",
    SET_ADDRESS: "SET_ADDRESS",
    SET_ZIP_CODE: "SET_ZIP_CODE",
    SET_CITY: "SET_CITY",
    SET_STATE: "SET_STATE",
    SET_AFFILIATES: "SET_AFFILIATES",
    SET_BENEFIT: "SET_BENEFIT",
    SET_ACTIVELY_AT_WORK_HOURS: "SET_ACTIVELY_AT_WORK_HOURS",
    SET_UNMARRIED: "SET_UNMARRIED",
    SET_DOMESTIC_PARTNERSHIP: "SET_DOMESTIC_PARTNERSHIP",
    SET_SUBJECT_ERISA: "SET_SUBJECT_ERISA",
    SET_BRELLA_ENROLLMENT: "SET_BRELLA_ENROLLMENT",
    SET_VARIES_BY_CLASS: "SET_VARIES_BY_CLASS",
    UPDATE_CLASS: "UPDATE_CLASS",
    SET_NEW_ELIGIBILITY_INFO: "SET_NEW_ELIGIBILITY_INFO",
    SET_DBA_NAME: "SET_DBA_NAME",
    SET_COBRA: "SET_COBRA",
};

export const initialDataActionTypes = {
    SET_INITIAL_DATA: "SET_INITIAL_DATA",
    SET_FOOTER: "SET_FOOTER",
};

export const employeesActionTypes = {
    UPDATE_EMPLOYEE_LIST: "UPDATE_EMPLOYEE_LIST",
    CLEAN_EMPLOYEE_LIST: "CLEAN_EMPLOYEE_LIST",
    SET_EMPLOYEES: "SET_EMPLOYEES",
    SET_TOTAL_ACTIVE_EMPLOYEES_COUNT: "SET_TOTAL_ACTIVE_EMPLOYEES_COUNT",
    SET_TOTAL_INACTIVE_EMPLOYEES_COUNT: "SET_TOTAL_INACTIVE_EMPLOYEES_COUNT",
    SET_EMPLOYEES_ERROR: "SET_EMPLOYEES_ERROR",
    SET_EMPLOYEES_FIRST_PAGE_LOADING: "SET_EMPLOYEES_FIRST_PAGE_LOADING",
}

export const employeeActionTypes = {
    SET_EMPLOYMENT_TERMINATION_DETAILS: "SET_EMPLOYMENT_TERMINATION_DETAILS",
}

export const authActionTypes = {
    SET_AUTH_TOKEN: "auth/SET_AUTH_TOKEN",
    SET_LOGIN: "auth/SET_LOGIN",
    LOGOUT: "auth/LOGOUT",
    SET_USERNAME: "auth/SET_USERNAME",
    METADATA_SET: "METADATA_SET",
    METADATA_SETUP: "METADATA_SETUP",
    MFA_TYPE_SET: "MFA_TYPE_SET",
    MFA_PREPARE_SET: "MFA_PREPARE_SET",
    SET_NEW_TOKEN: "SET_NEW_TOKEN",
    MFA_IS_SETUP: "MFA_IS_SETUP",
    CLEAR_TOKEN: "CLEAR_TOKEN",
    MFA_REMEMBER_DEVICE_DAYS_SET: "MFA_REMEMBER_DEVICE_DAYS_SET"
}

export const portalActionTypes = {
    SET_PORTAL_DATA: "SET_PORTAL_DATA",
    EMPLOYER: "EMPLOYER",
};

export const bankAccountActionTypes = {
    SET_BANK_ACCOUNTS: "SET_BANK_ACCOUNTS",
    SET_BANK_ACCOUNT: "SET_BANK_ACCOUNT",
    SET_DEFAULT_BANK_ACCOUNT: "SET_DEFAULT_BANK_ACCOUNT",
    SET_SELECTED_BANK_ACCOUNT: "SET_SELECTED_BANK_ACCOUNT",
    SET_EDITION_VISIBILITY: "SET_EDITION_VISIBILITY",
    SET_CREATION_VISIBILITY: "SET_CREATION_VISIBILITY",
    DELETE_BANK_ACCOUNT: "DELETE_BANK_ACCOUNT",
    SET_BANK_ACCOUNTS_DATA: "SET_BANK_ACCOUNTS_DATA",
    SET_PENDING_VERIFICATION_BANK_ACCOUNTS: "SET_PENDING_VERIFICATION_BANK_ACCOUNTS",
    SET_PENDING_VERIFICATION_BANK_ACCOUNT: "SET_PENDING_VERIFICATION_BANK_ACCOUNT",
    SET_PENDING_VERIFICATION_BANK_ACCOUNT_FAILED: "SET_PENDING_VERIFICATION_BANK_ACCOUNT_FAILED",
    REMOVE_PENDING_VERIFICATION_BANK_ACCOUNT: "REMOVE_PENDING_VERIFICATION_BANK_ACCOUNT"

}

export const billingActionTypes = {
    SET_BILLING_DATA: "SET_BILLING_DATA",
    SET_IS_NOT_AVAILABLE_TO_PAY: "SET_IS_NOT_AVAILABLE_TO_PAY"
}

export const openEnrollmentTypes = {
    SET_DATA: 'open-enrollment/SET_DATA',
}

export const portalModalHeaderActionTypes = {
    SET_PREV_ROUTE: "SET_PREV_ROUTE",
};

export const navigationActionTypes = {
    SET_LEFT_NAV: "SET_LEFT_NAV"
}

export const footerActionTypes = {
    SET_FOOTER: "SET_FOOTER",
}

export const toastActionTypes = {
    SHOW: 'toast/SHOW',
    HIDE: 'toast/HIDE',
    CLEAR: 'toast/CLEAR',
    RESET: 'toast/RESET'
}