import styled from "styled-components";
import {
  Header,
  Button,
  Grid,
  List,
  Segment,
  Form,
  Input,
  Icon,
  Modal,
  Checkbox
} from "semantic-ui-react";

import {S3Image} from "../components/custom-common";

export const MFAContainer = styled.div`
  background-color: #fff;
  margin: 65px auto;
  overflow: hidden;
  padding-bottom: 80px;
  text-align: left;
  &.backupCodeWrapper {
   display: flex;
   flex-direction: row;
   justify-content: center;
   margin-top: 48px;
   padding-bottom: 24px;
   margin-bottom: 0px;
  }
  &.sectionWrapper{
    max-width: ${(props) => props.maxWidth}!important;
    padding-bottom: 15px;
    margin: 0 auto 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  &.splashWrapper{
    max-width: ${(props) => props.maxWidth}!important;
    padding-bottom: 10px;
    margin: 64px auto 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  &.successWrapper {
    max-width: ${(props) => props.maxWidth}!important;
    padding-bottom: 10px;
    margin: 101px auto 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    & .successWrapperColumn{
      display: flex!important;
      align-items: center!important;
      justify-content: center!important;
      flex-direction: column!important;
    }
  }
  &.backupCodeText{
    max-width: 355px;
    padding: 0 20px;
    text-align: left;
    margin-top: 24px;
    margin-bottom: 0px;
  }
  &.verifyMethodChange{
    max-width: ${(props) => props.maxWidth}!important;
    padding: 24px 29px;
    text-align: left;
    margin-top: 0!important;
    border-radius: 0px 0px 8px 8px;
    & > .container {
      margin-top: 0;
      padding-bottom: 10px;
    }
  }
  &.methodChange{
    padding-top: 125px;
    text-align: center;
    height: 92vh;
    background-color: #FAFAFA;
    & > .grid > .row{
      margin-top: 24px
    }
  }
  &.changeMethodField{
    margin-top: 0px;
    padding-bottom: 10px;
    margin-bottom: 0!important;
  }
`;

export const MFAMainContainer = styled.div`
  overflow: hidden;
  @media (max-height: 500px) {
    overflow-y: scroll;
  }
`;

export const MFASplashScreen = styled.div`
  padding-top: 82px;
`;

export const MFAHeading = styled(Header)`
  color: ${(props) => props.theme.site.colors.heading.primary};
  margin-bottom: 8px;
  &.dark{
    color: ${(props) => props.theme.site.colors.neutrals.neutral700};
    font-size: 16px!important;
  }
  &.success{
    margin-bottom: 16px;
    margin-top: 24px;
  }
`;

export const MFAText = styled.p`
  color: ${(props) => props.theme.site.colors.neutrals.neutral900};
  font-size: 14px;
  line-height: 21px;
  &.invalidAttempt {
    background-color: #fbe8e5;
    font-size: 12px;
    padding: 12px 16px;
    color: ${(props) => props.theme.site.colors.neutrals.neutral900}!important;
    span {
      font-weight: 700;
    }
  }
  &.alignLeft {
    text-align: left;
  }
  & > span{
    &.bold{
      font-weight: 700;
    }
  }
  &.settings{
    color: ${(props) => props.theme.site.colors.neutrals.neutral700}!important;
    font-size: 12px;
    margin-bottom: 6px;
  }
  &.settingsItalic{
    color: ${(props) => props.theme.site.colors.neutrals.neutral700}!important;
    font-style: italic;
    font-size: 12px;
    margin-bottom: 6px;
  }
  &.mfaReminder-title{
    color: ${(props) => props.theme.site.colors.neutrals.neutral900}!important;
    font-weight: 700!important;
    margin-bottom: 0px!important;
    font-size: 12px!important;
  }
  &.verified{
    display: inline-block;
    color: #43806C;
    font-weight: 700!important;
  }
  &.keyLabel{
    margin-bottom: 5px;
  }
  &.marginBottomZero{
    margin-bottom: 0px;
  }
  &.successText{
    color: ${(props) => props.theme.site.colors.neutrals.neutral900}!important;
    font-weight: 400!important;
    margin-bottom: 0px!important;
    font-size: 16px!important;
  }
  &.floatRight{
    float: right!important;
  }
`;

export const MFABox = styled.div`
  padding: 24px;
  background-color: ${(props) => props.theme.site.colors.neutrals.neutral50};
`;

export const MFALink = styled.a`
  font-weight: 700;
  color: ${(props) => props.theme.site.colors.button.primary.default};
  font-size: 14px;
  &.left {
    float: left;
  }
`;

export const MFAButton = styled(Button)`
  &.ui.button {
    background-color: ${(props) =>
      props.theme.site.colors.button.primary.default}!important;
    color: ${(props) => props.theme.site.colors.white}!important;
    &:hover {
      background-color: ${(props) =>
        props.theme.site.colors.button.primary.hover}!important;
    }
    &.medium {
      font-size: 14px;
      height: 33px;
      line-height: 14px;
      padding: 6px 12px;
    }
    &.disabled {
      background-color: ${(props) => props.theme.site.colors.neutrals.neutral400} !important;
      color: #fff;
    }
    &.submitPassword{
      width: 98px;
      display: inline-block;
      margin-top: 10px;
    }
  }
`;

export const MFAButtonOutlined = styled(Button)`
  &.ui.button {
    background-color: ${(props) => props.theme.site.colors.white};
    border: 1px solid
      ${(props) => props.theme.site.colors.button.primary.default};
    color: ${(props) => props.theme.site.colors.button.primary.default};
    margin-right: 24px;
    &:hover {
      background-color: ${(props) =>
        props.theme.site.colors.button.primary.hover};
      color: ${(props) => props.theme.site.colors.white};
    }
  }
  &.closeModaldisabled{
    margin-right: 8px!important
    &.disabled{
      background-color: ${(props) => props.theme.site.colors.neutrals.neutral600} !important;
      color: #fff;
    }
  }
  &.settings{
    height: 37px;
    line-height: 14px;
    max-width: 140px;
    padding: 0.8em 0.7em 0.8em;
  }
  &.printBtn{
    margin-right: 0px!important;
  }
  &.grey{
    color: ${(props) => props.theme.site.colors.button.outline.default} !important;
    border: 1px solid
      ${(props) => props.theme.site.colors.button.outline.default} !important;
      &:hover {
        background: transparent !important;
        border: 1px solid
          ${(props) => props.theme.site.colors.button.outline.hover} !important;
        color: ${(props) =>
          props.theme.site.colors.button.outline.hover} !important;
      }
  }
`;

export const MFAGrid = styled(Grid)`
  &.backUpCodesBox{
    background-color: ${(props) => props.theme.site.colors.neutrals.neutral100};
  }
  & .column > .column.flexEnd {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & > button {
      margin-right: 0px!important;
    }
  }
  & .column.backupCodeColumn{
    min-width: 274px;
  }
  & .column.backupCodeActionColumn{
    min-width: 165px;
  }
  & .row.noPadding{
    padding: 0px;
  }
  & .row.spacingTop{
    margin-top: 24px;
  }
  & .row.noVerticalPadding{
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const MFASegment = styled(Segment)`
  &.verifySegment {
    max-width: 348px;
    margin: 25px auto 0;
    padding: 20px 24px 10px;
    display: flex;
    flex-direction: column;
  }
  &.toastSegment {
    margin: auto;
    position: fixed;
    bottom: 45px;
    right: 10px;
    z-index: 99999;
    text-align: left;
  }
  &.mfaReminder{
    background: ${(props) => props.theme.site.colors.neutrals.neutral50}!important;
    padding: 16px 19px 16px 16px!important;
    border: none;
    box-shadow: none;
    & > p {
        font-size: 12px;
        font-weight: 400;
        color: ${(props) => props.theme.site.colors.neutrals.neutral900}!important;
        &.linkText {
          display: flex;
          align-items: flex-start;
          & > i{
            margin-left: 6px;
        }
      }
    }
  }
  &.mfaSettingBox{
    background: ${(props) => props.theme.site.colors.neutrals.neutral50}!important;
    border: none!important;
  }
  &.mfaSettingText{
    font-size: 14px!important;
  }
  &.closeBackupContent{
    text-align: center;
    margin-top: 0px!important;
    padding-top: 0px!important;
  }
  &.footerAction{
    text-align: right;
    padding: 0px!important;
    margin: 0px!important;
    display: flex!important;
    justify-content: flex-end;
  }
`;

export const MFACustomCard = styled.div`
    padding: 16px;
    border-radius: 8px;
    border: 1px solid ${(props) => props.theme.site.colors.neutrals.neutral300};
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.05);
    background: #FFF;
    text-align: ${(props) => props.textAlign};
`;

export const MFACodeBox = styled(Segment)`
  &.ui.segment {
    box-shadow: none;
    margin: 0 auto;
    border: none;
    padding-top: 12px;
    & .ui.grid > .column:not(.row),
    .ui.grid > .row > .column {
      text-align: center;
      padding: 16px 8px;
    }
  }
`;

export const MFAForm = styled(Form)`
  &.field{
    &.contentLeft {
      width: 100%;
      display: inline-block;
      text-align: left;
    }
  }
  & .field.methodField{
    display: flex!important;
    cursor: pointer;
    width: 100%!important;
    & > label {
      width: 100%!important;
      display: block!important;;
    }
  }
  & .field.methodFieldDisabled{
    display: flex!important;
    cursor: pointer;
    width: 100%;
    padding-top: 0!important;
    padding-bottom: 0!important;
    margin: 0.25em 0em!important;
    & > label {
      width: 100%!important;
      display: block!important;;
    }
  }

  & .field.verifyWrapper{
    width: 300px!important;
    display: inline-block!important;
    margin-right: 16px!important;
  }

  & .field.verifyLabel{
    width: 80%!important;
    background-color: transparent!important;
    padding: 8px 0 3px!important;
    font-size: 14px!important;
  }

  & .ui.header.methodsListHeader{
    margin-left: 30px;
    font-size: 16px;
  }

  &.saveCodes{
    display: flex;
    justify-content: center;
  }
`;


export const MFAList = styled(List)`
    &.ui.ordered.list{
        margin-left: 0;
        & > .item {
            padding-left: 3rem;
            margin-bottom: 32px;
            padding-right: 1.8rem;
            &:before{
                background-color: ${(props) =>
                  props.theme.site.colors.brand.secondary};
                border-radius: 50px;
                color: ${(props) => props.theme.site.colors.white};
                height: 32px;
                margin-left: -3rem;
                text-align: center;
                width: 32px;
                line-height: 30px;
                font-weight: bold
            }
            & .content{
                font-size: 14px;
                font-weight: 400;
                line-height: 150%;
                & > span{
                    font-weight: 700;
                    color: ${(props) =>
                      props.theme.site.colors.button.primary.default};
                }
            }
        }
    }

`;

export const MFALabel = styled.label`
      font-weight: 700 !important;
      font-size: 14px !important;
      display: flex !important;
      align-items: center;
      &.labelVerify {
        background-color: transparent;
        padding: 0 0 3px;
        font-size: 14px;
      }
      &.labelSavedCodes {
        font-weight: 400 !important;
        & > .input {
          margin-right: 10px!important;
        }
      }
      &.methodLabel{
        width: 100%;
        background-color: transparent;
        & div {
          box-shadow: none;
        }
      }
`;

export const MFATextError = styled.p`
  color: #d82000;
  font-size: 12px;
  line-height: 21px;
  text-align:left;
  &.verifyError{
    text-align: left;
  }
`;

export const MFAInput = styled(Input)`
  &.ui.error.input{
    & > input {
      border-color: #D82000!important;
      display: inline-block!important;
    }
  }
  &.verifyInput {
    display: flex;
    & > input {
      width: 100%;
      background-color: transparent;
      padding: 0 0 3px;
      font-size: 14px:
    }
  }
`;

export const MFAIcon = styled(Icon)`
  &.verifiedIcon{
    background-color: transparent;
    color: #43806C;
    font-weight: 400 !important;
    font-size: ${props => props.sizeCustom ? props.sizeCustom : 'inherit'};
    margin-right: 16px;
  }
  &.settingsIcon{
    cursor: pointer;
    margin-left: 5px!important;
    font-size: 12px;
  }
`;
export const MFAImage = styled(S3Image)``;

export const MFAEnableSettingBox = styled.div`
  display: block;
  padding: 20px;
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.site.colors.neutrals.neutral900};
  background-color: #E3EEED;
  margin-top: 10px;
  margin-bottom: 24px;
  display: flex;
  align-items: baseline;
`;

export const MFASettingItem = styled.div`
  border-top: 1px solid #E0E0E0;
  padding: 24px 0;
`;

export const MFAMethodContainer = styled(Segment)`
    display: ${props => props.method === 'true' ? 'flex' : 'block'};
    align-items: center;
    justify-content: space-between;
    & > p {
        color: ${props => props.theme.site.colors.neutrals.neutral900};
        font-size: 14px;
        font-weight: 400
    }
`

export const MFAMethodTitle = styled(Header)`
    font-family: Raleway!important;
    color: ${props => props.method === 'true' ? props.theme.site.colors.neutrals.neutral700 : props.theme.site.colors.neutrals.neutral900}!important;
    margin-bottom: 0px;
    font-size: 16px!important;
    &.disabled{
      font-weight: 400!important;
      color: #757575!important
    }
`

export const MFAMethodComingSoon = styled.span`
    background-color: ${props => props.theme.site.colors.neutrals.neutral100};
    width: 106px;
    height: 26px;
    color: ${props => props.theme.site.colors.black};
    padding: 4px 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    border-radius: 100px;
`

export const MFAModal = styled(Modal)`
  &.warnBackupCodes {
    width: 525px!important;
  }
  &.warnReConfigure {
    width: 570px!important;
  }
  &.backupCodesModal {
    width: 650px!important;
  }
  & > #backUpCodes{
    background-color: ${props => props.theme.site.colors.neutrals.neutral100};
  }
  & .header {
    display: flex!important;
    justify-content: space-between!important;
  }
  & .header > p {
    font-size: 16px!important;
    display: inline-block;
    margin-bottom: 0px;
  }
`

export const MFACheckbox = styled(Checkbox)``;
