import moment from 'moment';
import React from 'react'
import { DateInput } from 'semantic-ui-calendar-react'
import { displayDateFormat } from '../utils/date'

const DateSelector = ({
    onInvalid = () => {},
    ...props
}) => (
    <DateInput
        closable={true}
        duration={0}
        animation={''}
        startMode={'month'}
        dateFormat={displayDateFormat}
        placeholder={displayDateFormat}
        hideMobileKeyboard={true}
        onBlur={e => (
            !!e && props.value && !moment(props.value).isValid() && onInvalid({
                name: e.target.name,
                value: e.target.value
            })
        )}
        {...props}
    />
)

export default DateSelector;