import React, {Component} from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import moment from "moment";
import Concierge from "../../concierge";
import STRATEGIC_PARTNER from "../../../constants/strategicPartners";
import {ClickableText, CustomDivider} from "../../custom-common";

const DRAFT = 'Draft'
const IN_APPROVAL_PROCESS = 'In Approval Process'
const IN_APPROVAL = 'In approval'
const VOIDED = 'Voided'
const EXPIRED = 'Expired'
const TERMINATED = 'Terminated'
const ISSUED = 'Issued'
const FINALIZED = 'Finalized'
const CANCELED = 'Canceled'

const mapStateToProps = (state, ownProps) => {
    return {
        ...ownProps,
        currentInvoices: state.billing.currentInvoices,
        previewInvoice: state.billing.previewInvoice,
        historicalInvoices: state.billing.historicalInvoices,
        currentBilling: state.billing.currentBilling,
        pastDue: state.billing.pastDue,
        status: state.portal.contract.contract.status,
        contractGroupStatus: state.portal.contract.contractGroupStatus,
        policyEffectiveDate: state.portal.contract.contract.policyEffectiveDate,
        policyEndDate: state.portal.contract.contract.policyEndDate,
        selfBillThreshold: state.billing.selfBillThreshold,
        billingMethod: state.portal.contract.employer.billingMethod,
        showBannerSelfBill: state.billing.showBannerSelfBill,
        isSelfBill: state.billing.isSelfBill
    }
};


class BillingAlert extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false
        }
    }

    manageModal = (value) => {
        this.setState({open: value})
    }

    bannerPastDue = ( firstPastDueInvoice ) => {
        const {year, month} = firstPastDueInvoice.billingPeriod;
        const monthName = moment().month(month-1).format('MMMM');
        const wholeyear = moment().year(year).format('YYYY');
        return <React.Fragment>
            <div className="billingAlert past-due">
                        <span className={"smaller"}>Your invoice for {monthName} {wholeyear} coverage is past due. To pay, go to your <span
                            onClick={this.props.switchToOverview} className={"hoverable"}><b>account summary</b></span>.
                             Questions? Contact your {STRATEGIC_PARTNER.LABEL} account manager.
                        </span>
            </div>
            <CustomDivider hidden/>
        </React.Fragment>
    }

    bannerDue = ( billingPeriod ) => {
        const {switchToOverview} = this.props
        const month = moment('' + billingPeriod.month).format("MMMM");
        const year = moment('' + billingPeriod.year).format("YYYY");
        return <React.Fragment>
            <div className="billingAlert due">
                <span className={"smaller"}>Your bill for {month} {year} coverage is due. To pay, please go to your <span
                onClick={switchToOverview} className={"hoverable"}><b>account summary</b></span>.
                </span>
            </div>
            <CustomDivider hidden/>
        </React.Fragment>
    }

    bannerPreview = ( previewInvoice ) => {
        const month = moment('' + previewInvoice.billingPeriod.month).format("MMMM");
        const year = moment('' + previewInvoice.billingPeriod.year).format("YYYY");
        return <React.Fragment>
            <div vertical className="billingAlert preview-available">
                <span className={"smaller"}>Your invoice for {month} {year} coverage is ready to be previewed. <Link
                    to={{pathname: "/portal/invoice/" + previewInvoice.id, state: {type: 'preview'}}}>Review invoice</Link> to see details.</span>
            </div>
            <CustomDivider hidden/></React.Fragment>
    }

    renderAlert = () => {
        const {
            previewInvoice,
            currentBilling,
            currentInvoices,
            pastDue,
            historicalInvoices,
            showBannerSelfBill,
            isSelfBill
        } = this.props;

        const isPastDueDiferentZero = !!pastDue && pastDue !== "0.00"
        const firstPastDueInvoice = historicalInvoices.find((e) => e.paymentStatus === "Past due" || e.paymentStatus === "Partially paid");

        if (isSelfBill && showBannerSelfBill && isPastDueDiferentZero) {
            if (firstPastDueInvoice) return this.bannerPastDue(firstPastDueInvoice)
            return null
        }

        if (isPastDueDiferentZero && !isSelfBill) {
            if (firstPastDueInvoice) return this.bannerPastDue(firstPastDueInvoice)
            return null
        }

        if (currentInvoices?.length > 0 && currentBilling !== "0.00") {
            return this.bannerDue(currentInvoices[0].billingPeriod)
        }

        if (previewInvoice && !isSelfBill) {
            return this.bannerPreview(previewInvoice)
        }

        return null;
    }

    renderContractStatusAlert = () => {
        const {status, policyEffectiveDate, policyEndDate, contractGroupStatus} = this.props;
        if (contractGroupStatus === IN_APPROVAL) {
            const versionEffectiveDateFormat = moment(policyEffectiveDate).format("MMMM, DD, YYYY");
            return (
              <>
                  <div className="contractGroupStatusAlert inApproval">
                    <span className={"smaller"}>
                        Policy information below is effective <strong style={{color: 'black'}}>{versionEffectiveDateFormat}</strong>, <i>pending carrier approval</i>.
                    </span>
                  </div>
                  <CustomDivider hidden/>
              </>
            )
        }

        if (contractGroupStatus === ISSUED && ( [FINALIZED, IN_APPROVAL, IN_APPROVAL_PROCESS].includes(status) ) ) {
            const versionEffectiveDateFormat = moment(policyEffectiveDate).format("MMMM, DD, YYYY");
            return (
              <>
                  <div className="contractGroupStatusAlert terminated">
                    <span className={"smaller"}>
                        Policy information below is effective <strong style={{color: 'black'}}>{versionEffectiveDateFormat}</strong>
                    </span>
                  </div>
                  <CustomDivider hidden/>
              </>
            )
        } else if (contractGroupStatus === ISSUED && (status === EXPIRED || status === DRAFT)) {
            return (
              <>
                  <div className="contractGroupStatusAlert expired">
                    <span className={"smaller"}>
                        Warning: The policy information below is expired. Your annual renewal may be in progress,
                        <ClickableText onClick={() => this.manageModal(true)}> contact us </ClickableText>
                        to confirm next steps
                    </span>
                  </div>
                  <CustomDivider hidden/>
              </>
            )
        }

        if (contractGroupStatus === TERMINATED && (status === TERMINATED || status === VOIDED)) {
            const termVersionEndDateFormat = moment(policyEndDate).format("MMMM, DD, YYYY");
            return <React.Fragment>
                <div className="contractGroupStatusAlert terminated">
                    <span className={"smaller"}>
                        This policy has been terminated as of <strong style={{color: 'black'}}>{termVersionEndDateFormat}</strong>.
                    </span>
                </div>
                <CustomDivider hidden/>
            </React.Fragment>
        }

        if (contractGroupStatus === CANCELED) {
            return <React.Fragment>
                <div className="contractGroupStatusAlert voided">
                    <span className={"smaller"}>
                        Policy has been canceled by the policyholder and is considered void from its effective date.
                    </span>
                </div>
                <CustomDivider hidden/>
            </React.Fragment>
        }

        return null;
    }

    render() {
        const {open} = this.state
        return (
            <React.Fragment>
                <Concierge open={open} onManage={this.manageModal} hasDedicatedConcierge/>
                {this.renderAlert()}
                {this.renderContractStatusAlert()}
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps)(withRouter(BillingAlert));
