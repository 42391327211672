import React, {Component} from 'react'
import {withRouter} from "react-router-dom";
import {Dropdown, Icon, Message, Segment, Table} from "semantic-ui-react";
import {connect} from "react-redux";
import axios from "axios";
import {
    buildFullName,
    delay,
    dowloadBlob,
    formatDate,
    formatMoney,
    formatMontlyCost,
    resolveErrorMessage,
    resolveTier
} from "../../../utils/utils";
import moment from "moment";
import Concierge from "../../concierge";
import PortalModalHeader from "../portalModalHeader";
import PortalFooter from "../portalFooter";
import {Button, DownloadIcon, Header, PdfIcon} from "../../base"
import {setPrevRoute} from "../../../actions/portalModalHeaderActions";
import {setTerminationDetails} from "../../../actions/employeeActions";
import {EmploymentTerminationCard} from "../employeeTermination/employment/partials/employmentTerminationCard";
import {StrategicPartnerLabel} from "../../common";
import STRATEGIC_PARTNER from "../../../constants/strategicPartners";
import {SSNDisplay, DOBDisplay, CustomGrid, CustomDivider} from "../../custom-common";

const mapStateToProps = ({employeeTermination, portal: {contract}}, ownProps) => ({
    employeeTermination,
    contract,
    ...ownProps,
});

const mapDispatchToProps = dispatch => ({
    setPrevRoute: portalModalHeader => dispatch(setPrevRoute(portalModalHeader)),
    setTerminationDetails: employeeTermination => dispatch(setTerminationDetails(employeeTermination)),
})

class Employee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            data: null,
            showFamilyDetails: false,
            coveragePacketLoading: false,
            coveragePacketError: null,
            conciergeOpen: false,
            stateCode: null,
            groupSizeSegment: null,
            policyNumber: null,
            contactId: null,
        }
    }

    async componentDidMount() {
        const {match, setPrevRoute, setTerminationDetails} = this.props;
        const id = match.params.id;
        this.setState({loading: true, error: null})
        try {
            const payload = {"employeeId": id}
            const {data} = await axios.post("/api/employer/v2/prepareEmployeeDetails", payload, {headers: {'Content-Type': 'application/json'}});
            const personalData = data.censusMember ?? data.certificateInformation?.certificateHolder;
            const fullName = personalData && buildFullName(personalData.firstName, personalData.middleName, personalData.lastName, personalData.suffix);
            this.setState({
                data,
                loading: false,
                stateCode: data.certificateInformation?.contract.issueState,
                groupSizeSegment: data.certificateInformation?.contract.groupSizeSegment,
                policyNumber: data.certificateInformation?.contract.policyNumber,
                contactId: data.certificateInformation?.certificateHolder.id,
                fullName
            })
            const tier = resolveTier(data.certificateInformation?.certificate?.familyTier);
            setTerminationDetails({
                ...data.employeeTerminationDetails,
                tier,
            });
            setPrevRoute({prevRoute: '/portal/employees'});
        } catch (e) {
            console.warn(e)
            this.setState({loading: false, error: "Unable to get employee info"})
        }
    }

    toggleFamilyDetails = () => {
        const currentVisibility = this.state.showFamilyDetails;
        this.setState({showFamilyDetails: !currentVisibility})
    }

    downloadCoveragePacket = async () => {
        try {
            const { stateCode, groupSizeSegment, policyNumber, contactId } = this.state
            this.setState({coveragePacketLoading: true, coveragePacketError: null})

            let result = await axios.post('/api/employer/v2/generateEmployeeCoveragePacket', {contactId, stateCode, groupSizeSegment, policyNumber})
            let status = result.data.status

            while (status === 'pending') {
                await delay(4000)
                result = await axios.post('/api/employer/v2/isEmployeeCoveragePacketGenerated', {contactId, stateCode, groupSizeSegment})
                status = result.data.status
            }

            if (status !== 'success') return this.setState({
                coveragePacketLoading: false,
                coveragePacketError: 'Unable to generate the document',
            })

            result = await axios.post('/api/employer/v2/downloadEmployeeCoveragePacket', {stateCode, contactId, groupSizeSegment}, { responseType: 'blob' })

            let packetName = result.headers["x-packetname"];
            if(!packetName){
                const date = moment().format('YYYY-MM-DD')
                packetName = `coverage-packet-${date}.pdf`;
            }
            const {data} = result
            dowloadBlob(packetName, 'application/pdf', data)
            this.setState({ coveragePacketLoading: false })
        } catch (e) {
            console.warn(e)
            this.setState({
                coveragePacketLoading: false,
                coveragePacketError: resolveErrorMessage(e, 'Unable to generate the document')
            })
        }
    }

    manageConciergeModal = (value) => {
        this.setState({conciergeOpen: value})
    }

    handleTerminateEmployment() {
        const {match, history} = this.props;
        const id = match.params.id;
        history.push({pathname: `/portal/employee/${id}/employment-termination`});
    }

    isDateToDismiss(date) {
        const now = moment();
        return now.diff(date, 'days') >= 30;
    }

    renderTerminationEventCardIfNecessary() {
        const {employeeTermination} = this.props;
        const {status, decisionDate, autoApproveDate} = employeeTermination;

        if (status !== 'Pending for Approval' && status !== 'Completed' && status !== 'Rejected') {
            return null;
        }

        if (decisionDate) {
            const mDecisionDate = moment(decisionDate);
            if (this.isDateToDismiss(mDecisionDate)) return null;
        } else if (autoApproveDate) {
            const mAutoApproveDate = moment(autoApproveDate);
            if (this.isDateToDismiss(mAutoApproveDate)) return null;
        }
        const EventCard = EmploymentTerminationCard;
        return (
            <>
                <CustomDivider hidden/>
                <EventCard/>
                <CustomDivider hidden/>
            </>
        );
    }

    render() {
        const {
            loading,
            showFamilyDetails,
            error,
            data,
            coveragePacketLoading,
            coveragePacketError,
            conciergeOpen,
            fullName,
        } = this.state;
        const {contract, employeeTermination: {terminationDate}} = this.props;
        let personalData, billingGroupLabel, classDescription
        let moderate, severe, catastrophic, chronic, mental
        if (data) {
            personalData = data.censusMember ? data.censusMember : data.certificateInformation.certificateHolder

            if (data.certificateInformation) {
                moderate = data.certificateInformation.certificate.assetCoverages.moderate
                severe = data.certificateInformation.certificate.assetCoverages.severe
                catastrophic = data.certificateInformation.certificate.assetCoverages.catastrophic
                chronic = data.certificateInformation.certificate.assetCoverages.chronic
                mental = data.certificateInformation.certificate.assetCoverages.mental

                billingGroupLabel = data.certificateInformation.certificate.billingGroupCustomField || data.certificateInformation.certificate.billingGroupField
                classDescription = data.certificateInformation.certificate.ratedClass.groupClass.description
            } else {
                billingGroupLabel = data.censusMember.billingGroup ? "Billing group" : null
                classDescription = contract.ratedClasses.find(item => item.groupClass.id = data.censusMember.groupClassId)?.groupClass?.description
            }
        }

        const editButtonTrigger = <Button data-qa-button_edit primary>Edit <Icon name={"dropdown"} style={{margin: 0}}/></Button>;

        const isEmploymentTerminationShowable = () => {
            const terminationDateInFuture = terminationDate && moment(terminationDate).isAfter(moment());
            return personalData?.employmentStatus === 'Active' && !terminationDateInFuture;
        };

        const isTerminationButtonShowable = () => {
            return isEmploymentTerminationShowable();
        };

        return (
            <React.Fragment>
                <PortalModalHeader showRightMenu modalTitle={fullName}/>
                <div className="employer-portal-container" style={{background: 'white'}}>
                    <CustomGrid container stackable columns={1} centered fitted={'horizontally'}>
                        <CustomGrid.Column>
                            <CustomDivider hidden/>
                            <Segment padded loading={loading} basic>
                                {error && <Message negative>{error}</Message>}
                                {data &&
                                <React.Fragment>
                                    <p style={{textAlign: "center"}}>
                                        To update employee information, <span className="linkText" onClick={() => this.manageConciergeModal(true)}>send us a message </span>
                                        or call {STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}.
                                    </p>
                                    {this.renderTerminationEventCardIfNecessary()}
                                    <CustomDivider hidden/>
                                    <CustomGrid columns="equal">
                                            <CustomGrid.Row>
                                                <CustomGrid.Column>
                                                    <Header as={"h2"}>{fullName}</Header>
                                                </CustomGrid.Column>
                                                <CustomGrid.Column textAlign={"right"}>
                                                    {isTerminationButtonShowable() && <Dropdown trigger={editButtonTrigger} icon={null} direction={"left"}>
                                                        <Dropdown.Menu direction={"left"} style={{width: '211px', marginTop: '16px', padding: '8px 0 12px 0'}}>
                                                            {isEmploymentTerminationShowable() && <Dropdown.Item
                                                                data-qa-button_terminate_employ
                                                                onClick={(_) => {
                                                                    this.handleTerminateEmployment()
                                                                }}
                                                                text={"Terminate employment"}
                                                            />}
                                                        </Dropdown.Menu>
                                                    </Dropdown>}
                                                </CustomGrid.Column>
                                            </CustomGrid.Row>
                                        </CustomGrid>
                                    <Header as={"h3"}>Personal details</Header>
                                    <CustomGrid columns={2} relaxed>
                                        <CustomGrid.Column className={"small"}>
                                            <p>
                                                <b>Name</b><br/>{fullName}
                                            </p>
                                            <p>
                                                <b>Email</b><br/>{personalData.email}
                                            </p>
                                            <p>
                                                <DOBDisplay dob={formatDate(personalData.birthDate)} visibilityIconVisible={false}/>
                                            </p>
                                            <p>
                                                <b>Gender</b><br/>{personalData.gender}
                                            </p>
                                            <p>
                                                <SSNDisplay ssn={personalData.socialSecurityNumber} visibilityIconVisible={false}/>
                                            </p>
                                        </CustomGrid.Column>
                                        <CustomGrid.Column className={"small"}>
                                            <p>
                                                <b>Address</b><br/>{personalData.address && personalData.address.street}
                                            </p>
                                            <p>
                                                <b>City</b><br/>{personalData.address && personalData.address.city}
                                            </p>
                                            <p>
                                                <b>State</b><br/>{personalData.address && personalData.address.state}
                                            </p>
                                            <p>
                                                <b>Zip
                                                    code</b><br/>{personalData.address && personalData.address.zipCode}
                                            </p>
                                        </CustomGrid.Column>
                                    </CustomGrid>

                                    <CustomDivider hidden medium/>
                                    <Header as={"h3"}>Employment details</Header>
                                    <CustomDivider hidden/>
                                    <CustomGrid columns={2} relaxed>
                                        <CustomGrid.Column className={"small"}>
                                            <Table basic={"very"} padded className={"borderTop"}>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell><b>Date of hire</b></Table.Cell>
                                                        <Table.Cell>{formatDate(personalData.dateOfHire)}</Table.Cell>
                                                    </Table.Row>
                                                    {billingGroupLabel &&
                                                    <Table.Row>
                                                        <Table.Cell><b>{billingGroupLabel}</b></Table.Cell>
                                                        <Table.Cell>{personalData.billingGroup}</Table.Cell>
                                                    </Table.Row>}
                                                    {personalData.payrollFrequency &&
                                                    <Table.Row>
                                                        <Table.Cell><b>Payroll frequency</b></Table.Cell>
                                                        <Table.Cell>{personalData.payrollFrequency}</Table.Cell>
                                                    </Table.Row>}
                                                    {classDescription &&
                                                    <Table.Row>
                                                        <Table.Cell><b>Class description</b></Table.Cell>
                                                        <Table.Cell>{classDescription}</Table.Cell>
                                                    </Table.Row>}
                                                </Table.Body>
                                            </Table>
                                        </CustomGrid.Column>
                                        <CustomGrid.Column className={"small"}>
                                            <Table basic={"very"} padded className={"borderTop"}>
                                                <Table.Body>
                                                    <Table.Row>
                                                        <Table.Cell><b>Employment status</b></Table.Cell>
                                                        <Table.Cell>{personalData.employmentStatus}</Table.Cell>
                                                    </Table.Row>
                                                    <Table.Row>
                                                        <Table.Cell><b>Termination date</b></Table.Cell>
                                                        <Table.Cell>{formatDate(personalData.terminationDate)}</Table.Cell>
                                                    </Table.Row>
                                                    {personalData.terminationReason && <Table.Row>
                                                        <Table.Cell><b>Termination reason</b></Table.Cell>
                                                        <Table.Cell>{personalData.terminationReason}</Table.Cell>
                                                    </Table.Row>}
                                                </Table.Body>
                                            </Table>
                                        </CustomGrid.Column>
                                    </CustomGrid>

                                    {data.certificateInformation &&
                                    <React.Fragment>
                                        {data.certificateInformation.dependents && data.certificateInformation.dependents.length > 0 &&
                                        <React.Fragment>
                                            <CustomDivider hidden/>
                                            <Header as={"h3"}>Family details</Header>
                                            <Button basic primary
                                                    onClick={this.toggleFamilyDetails}>{showFamilyDetails ? 'Hide' : 'Show'}
                                                <Icon
                                                    style={{marginRight: 0}}
                                                    name={showFamilyDetails ? 'caret up' : 'caret down'}/></Button>
                                            {showFamilyDetails && data.certificateInformation.dependents.map(item => {
                                                return (<React.Fragment key={item.id}>
                                                    <Header as={"h4"}>{item.relToEmployee}</Header>
                                                    <CustomGrid columns={2} relaxed>
                                                        <CustomGrid.Column className={"small"}>
                                                            <p>
                                                                <b>Name</b><br/>{buildFullName(item.firstName, item.middleName, item.lastName, item.suffix)}
                                                            </p>
                                                            <p>
                                                                <b>Email</b><br/>{item.email}
                                                            </p>
                                                            <p>
                                                                <DOBDisplay dob={formatDate(item.birthDate)} visibilityIconVisible={false}/>
                                                            </p>
                                                            <p>
                                                                <b>Gender</b><br/>{item.gender}
                                                            </p>
                                                            <p>
                                                                <SSNDisplay ssn={item.socialSecurityNumber} visibilityIconVisible={false}/>
                                                            </p>
                                                        </CustomGrid.Column>
                                                        <CustomGrid.Column className={"small"}>
                                                            <p>
                                                                <b>Address</b><br/>{item.address && item.address.street}
                                                            </p>
                                                            <p>
                                                                <b>City</b><br/>{item.address && item.address.city}
                                                            </p>
                                                            <p>
                                                                <b>State</b><br/>{item.address && item.address.state}
                                                            </p>
                                                            <p>
                                                                <b>Zip
                                                                    code</b><br/>{item.address && item.address.zipCode}
                                                            </p>
                                                            {item.relToEmployee === "Child" && <p>
                                                                <b>Disabled</b><br/>{item.isQualifiedForCoverage ? "Yes" : "No"}
                                                            </p>}
                                                        </CustomGrid.Column>
                                                    </CustomGrid>
                                                </React.Fragment>)
                                            })
                                            }
                                        </React.Fragment>}
                                    </React.Fragment>
                                    }
                                    <CustomDivider hidden medium/>
                                    <Header as={"h3"}>Coverage details</Header>
                                    {data.certificateInformation ? (
                                        <>
                                        <CustomGrid columns={2} relaxed>
                                            <CustomGrid.Column className={"small"}>
                                                <Table basic={"very"} padded>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell colSpan={2}>
                                                                <span
                                                                    className={"neutral600Text"}><b>Benefit amounts</b></span>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell><b>Moderate</b></Table.Cell>
                                                            <Table.Cell>{formatMoney(moderate)}</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell><b>Severe</b></Table.Cell>
                                                            <Table.Cell>{formatMoney(severe)}</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell><b>Catastrophic</b></Table.Cell>
                                                            <Table.Cell>{formatMoney(catastrophic)}</Table.Cell>
                                                        </Table.Row>
                                                        {!!mental && <Table.Row>
                                                            <Table.Cell><b>Mental</b></Table.Cell>
                                                            <Table.Cell>{formatMoney(mental)}</Table.Cell>
                                                        </Table.Row>}
                                                        {!!chronic && <Table.Row>
                                                            <Table.Cell><b>Chronic</b></Table.Cell>
                                                            <Table.Cell>{formatMoney(chronic)}</Table.Cell>
                                                        </Table.Row>}
                                                    </Table.Body>
                                                </Table>
                                            </CustomGrid.Column>
                                            <CustomGrid.Column className={"small"}>
                                                <Table basic={"very"} padded>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell colSpan={2}>
                                                                <span className={"neutral600Text"}><b>Details</b></span>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell><b>Start date</b></Table.Cell>
                                                            <Table.Cell>{formatDate(data.certificateInformation.certificate.effectiveDate)}</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell><b>End date</b></Table.Cell>
                                                            <Table.Cell>{formatDate(data.certificateInformation.certificate.expirationDate)}</Table.Cell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.Cell><b>Tier</b></Table.Cell>
                                                            <Table.Cell>{resolveTier(data.certificateInformation.certificate.familyTier)}</Table.Cell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table>
                                            </CustomGrid.Column>
                                            <CustomGrid.Column>
                                                <p style={{paddingTop: '.7em'}}>
                                                    <span className={"small neutral600Text"}><b>Costs</b></span>
                                                </p>
                                                <Segment compact padded>
                                                    <span className={"textTtotalCost"}><b>Total cost</b></span>
                                                    <Header
                                                      as={"h3"}
                                                      color={"black"}
                                                      style={{marginTop: 0}}>
                                                        {formatMontlyCost(data.employerCost + data.employeeCost)}
                                                    </Header>
                                                </Segment>
                                                {data.employerCost !== 0 &&
                                                <React.Fragment>
                                                    <p className={"primaryText"}>Employer
                                                        cost {formatMontlyCost(data.employerCost)}</p>
                                                    <p className={"primaryText"}>Employee
                                                        cost {formatMontlyCost(data.employeeCost)}</p>
                                                </React.Fragment>
                                                }
                                            </CustomGrid.Column>
                                            {data.certificateInformation.dependents && data.certificateInformation.dependents.length > 0 &&
                                            <CustomGrid.Column>
                                                <Table basic={"very"} padded className={"small"}>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.Cell colSpan={2}>
                                                                <span className={"neutral600Text"}><b>Covered family members</b></span>
                                                            </Table.Cell>
                                                        </Table.Row>
                                                        {data.certificateInformation.dependents.map(item =>
                                                            <Table.Row key={item.id}>
                                                                <Table.Cell><b>{buildFullName(item.firstName, item.middleName, item.lastName, item.suffix)}, {moment().diff(item.birthDate, 'years')}</b></Table.Cell>
                                                                <Table.Cell>{item.relToEmployee}</Table.Cell>
                                                            </Table.Row>
                                                        )}
                                                    </Table.Body>
                                                </Table>
                                            </CustomGrid.Column>
                                            }
                                        </CustomGrid>
                                        <CustomDivider hidden medium/>
                                        <Header as={"h3"}>Document(s)</Header>
                                        <CustomGrid basic stackable columns={2} as={Segment}>
                                            <CustomGrid.Column style={{paddingLeft: 0}}>
                                                {coveragePacketError && <Message error> {coveragePacketError}</Message>}
                                                <Segment loading={coveragePacketLoading} className={"hovereable clickable"} onClick={this.downloadCoveragePacket}>
                                                    <PdfIcon/>
                                                    <Segment basic compact vertical style={{marginLeft: 50}}>
                                                        <b>Coverage packet</b>
                                                        <DownloadIcon/>
                                                        <p className={"smaller truncate-3 cp-download-msg"}>
                                                        This includes the limited benefit insurance certificate,
                                                        HIPAA notice of privacy practices and more.
                                                        </p>
                                                    </Segment>
                                                </Segment>
                                            </CustomGrid.Column>
                                        </CustomGrid>
                                        </>
                                    ) : (
                                        <p><i>This employee is not currently enrolled in <StrategicPartnerLabel/> coverage.</i></p>
                                    )}
                                </React.Fragment>}
                            </Segment>
                        </CustomGrid.Column>
                    </CustomGrid>
                </div>
                <PortalFooter/>
                <Concierge open={conciergeOpen} onManage={this.manageConciergeModal} hasDedicatedConcierge/>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Employee));
