import React from 'react'
import {S3Image} from "../../../custom-common";

const EventCardIcon = ({imageName, size, ...props}) => {

    return (
        <div style={{
            padding: '5px 16px 0 24px',
            display: 'inline-block',
            ...props
        }}>
            <S3Image
                name={imageName}
                width={size}
                height={size}
                alt='Event card icon'
                svg
            />
        </div>
    )
}

export default EventCardIcon
