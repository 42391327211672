const IMAGE = Object.freeze({
    WELCOME: {
        name: 'welcome',
        centered: true,
        common: true,
        width: 286,
    },
    CHECK_124PX: {
        name: 'check_success',
        centered: true,
        svg: true,
        common: true,
        width: 124,
    },
    CHECK_80PX: {
        name: 'check_success',
        centered: true,
        svg: true,
        common: true,
        width: 80
    },
    ENVELOPE_SIGNATURE: {
        name: 'envelope-signature',
        centered: true,
        width: 120
    },
    CHECK_CIRCLE_OUTLINE: {
        name: 'check_circle_outline',
        width: 22,
        common: true,
        centered: true,
        svg: true
    },
    HIGHLIGHT_OFF: {
        name: 'highlight_off',
        width: 18,
        common: true,
        centered: true,
        svg: true
    },
    CLOSE: {
        name: 'close',
        common: true,
        svg: true,
        width: 17
    },
    OFFICIAL_LOGO: {
        name: 'official_logo',
        height: 32,
        common: true,
        svg: true
    },
    OFFICIAL_LOGO_WHITE: {
        name: 'official_logo_white',
        common: true,
        svg: true
    },
    ICON_HOURGLASS: {
        centered: true,
        name: 'icon_hourglass',
        width: 56
    },
    LOGIN_BACKGROUND: {
        name: 'login_background',
    },
    ICON_CALENDAR: {
        name: 'icon_calendar',
        width: 940
    },
    ICON_CALENDAR_CIRCLED: {
        name: 'icon_calendar_circled',
        width: 940
    },
    SUCCESS_CONFETTI: {
        name: 'success_confetti',
    },
    SUCCESS_CONFETTI_120PX: {
        name: 'success_confetti',
        width: 120
    },
    ICON_MAP: {
        name: 'icon_map',
        width: 96
    },
    ICON_GEARS: {
        name: 'icon_gears'
    },
    STOPWATCH: {
        name: 'stopwatch'
    },
    PEACE_OF_MIND_PAYMENT_73PX: {
        width: 73,
        name: 'peace_of_mind_payment'
    },
    PEACE_OF_MIND_PAYMENT_147PX: {
        width: 147,
        name: 'peace_of_mind_payment'
    },
});

export default IMAGE;
