import {Grid, Segment} from "semantic-ui-react";
import React from "react";
import EventCardIcon from "../../common/eventCardIcon";
import {useSelector} from "react-redux";
import {selectEmployeeTermination} from "../../../../../selectors/employeeTermination";
import {formatDate} from "../../../../../utils/utils";
import STRATEGIC_PARTNER from "../../../../../constants/strategicPartners";

export const EmploymentTerminationCard = () => {
    const employeeTermination = useSelector(selectEmployeeTermination);
    const {
        autoApproveDate,
        changeExplanationCopy,
        coverageEndDate,
        coverageImpacted,
        decisionDate,
        reason,
        status,
        terminationDate,
        tier,
        willBePendingForApproval,
    } = employeeTermination;
    const pendingForApproval = willBePendingForApproval || status === 'Pending for Approval';
    const approved = status === 'Completed' && decisionDate;

    const getImageName = () => {
        return pendingForApproval ? 'qle_warning' : approved ? 'qle_checkmark' : 'qle_calendar';
    }

    const borderColor = pendingForApproval ? {borderColor: '#F0A446'} : {};

    const background = pendingForApproval ? '#FFF7EC' : '#FAFAFA';

    const renderDescription = () => {
        let description;
        if (pendingForApproval) {
            description = (<i>
                This change impacts historical coverage and requires carrier approval before it can be processed. If you have any questions, please reach out to <span
                style={{whiteSpace: "nowrap"}}>{STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}.</span>
            </i>);
        } else if (approved) {
            const explanation = changeExplanationCopy ? `${changeExplanationCopy}. ` : "";
            description = (<i>
                {explanation}If you have any questions, please reach out to <span style={{whiteSpace: "nowrap"}}>{STRATEGIC_PARTNER.CONTACT_SUPPORT.NUMBER}.</span>
            </i>);
        } else {
            description = coverageImpacted ? (<i>This change has an impact to coverage, see details below for more information.</i>) :
                (<i>No coverage is ending as a result of this employment termination.</i>);
        }
        return description;
    }

    const renderChangeRequestLanguage = () => {
        return pendingForApproval ? (<p style={{marginBottom: 0}}><b>Change request:</b> Pending carrier approval.</p>) : approved ?
            (<p style={{marginBottom: 0}}><b>Change request:</b> Approved on {formatDate(decisionDate, decisionDate, "MMM Do, YYYY")}.</p>) : null;
    };

    return (
        <Segment style={{borderRadius: '0.5rem', ...borderColor}}>
            <Grid>
                <Grid.Row style={{padding: '24px 0 24px 0'}}>
                    <Grid.Column style={{width: '80px', padding: 0}}>
                        <EventCardIcon imageName={getImageName()} size={40}/>
                    </Grid.Column>
                    <Grid.Column style={{width: '85%', padding: 0}}>
                        <p data-qa_ee_termination_reason style={{marginBottom: '8px'}}><b>Employment Termination:</b> {reason}</p>
                        {!autoApproveDate && renderChangeRequestLanguage()}
                        <p style={{fontSize: '14px'}}>{renderDescription()}</p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row style={{borderRadius: '0 0 0.5rem 0.5rem', background: background, padding: '16px 0 16px 0'}}>
                    <Grid.Column style={{width: '80px', padding: 0}}/>
                    <Grid.Column style={{width: 'max-content', padding: '0 80px 0 0'}}>
                        <div>
                            <p style={{fontSize: '14px', fontWeight: 700, color: '#616161', lineHeight: '21px', marginBottom: 0}}>Termination date:</p>
                            <h4 style={{marginTop: 0}}>{formatDate(terminationDate, terminationDate, "MMM Do, YYYY")}</h4>
                        </div>
                    </Grid.Column>
                    {coverageImpacted && <Grid.Column style={{width: 'max-content', padding: 0}}>
                        <div>
                            <p style={{fontSize: '14px', fontWeight: 700, color: '#616161', lineHeight: '21px', marginBottom: 0}}>{tier} coverage ends on:</p>
                            <h4 style={{marginTop: 0}}>{formatDate(coverageEndDate, coverageEndDate, "MMM Do, YYYY")}</h4>
                        </div>
                    </Grid.Column>}
                </Grid.Row>
            </Grid>
        </Segment>
    )
};
